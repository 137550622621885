import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import { BasketWidgetConfiguration } from './basket-widget.model';

export const BASKET_WIDGET: WidgetInfo<BasketWidgetConfiguration> = {
  type: WidgetType.BASKET,
  defaultConfiguration: () => ({}),
  thumb: '',
  name: 'todo: Basket',
  description: {},
  widgetComponent: () => import('./basket-widget.component').then(module => module.BasketWidgetComponent)
};
