import { DOCUMENT } from '@angular/common';
import { HttpContext, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import {
  AUTH_CONTEXT,
  AuthInterceptor,
  AuthInterceptorConfig,
  AuthService,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceAccessTokenProvider,
  ServiceTokenInterceptorConfig,
  TenantService
} from '@celum/authentication';
import { TranslationHelper } from '@celum/ng2base';
import { PortalsProperties } from '@celum/portals/domain';
import { getRouteParamRecursiveForRoot, isCloudTokenUrl } from '@celum/portals/shared';

@Injectable()
export class PortalsAuthInterceptor extends AuthInterceptor {
  private readonly portalConfigUrl = `${PortalsProperties.properties.apiUrl}/configuration/`;

  constructor(
    authService: AuthService,
    protected router: Router,
    @Inject(DOCUMENT) document: Document,
    private tokenProvider: ServiceAccessTokenProvider,
    translation: TranslationHelper,
    @Inject(SERVICE_TOKEN_INTERCEPTOR_CONFIG) private interceptorConf: ServiceTokenInterceptorConfig<AuthInterceptorConfig>,
    private tenantService: TenantService
  ) {
    super(authService, router, document, tokenProvider, translation, interceptorConf);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Initial config request? Used to determine whether all calls need to be authenticated
    if (req.url.startsWith(this.portalConfigUrl)) {
      this.tokenProvider.clearTokenCache('cloud');
      // already stored? Check whether we need authentication
      const tenantId = this.tenantService.getCurrentTenantId();
      if (tenantId) {
        return super.intercept(req, next).pipe(catchError(error => this.handleUnauthorizedError(error)));
      }
      // Not stored? Make the request normally. If it fails with a 403 we will store the tenantId/portalId and return after login, ready to add the token
      return next.handle(req);
    }

    req = this.attachAuthContextIfNecessary(req);

    // All other calls should only be authenticated if the portal is protected or requires a cloud token
    // During login we don't yet have the portal configuration, that's why we also have to check whether we stored the tenantId
    return this.tenantService.getCurrentTenantId() || isCloudTokenUrl(this.interceptorConf, req.url)
      ? super.intercept(req, next).pipe(catchError(error => this.handleUnauthorizedError(error)))
      : next.handle(req);
  }

  private attachAuthContextIfNecessary(req: HttpRequest<any>): HttpRequest<any> {
    if (isCloudTokenUrl(this.interceptorConf, req.url)) {
      const portalId = this.getPortalIdOrSlug();

      if (portalId) {
        const context = new HttpContext().set(AUTH_CONTEXT, { portalId });

        req = req.clone({ context });
      }
    }

    return req;
  }

  private getPortalIdOrSlug(): string {
    return getRouteParamRecursiveForRoot('portalSlug', this.router);
  }

  private handleUnauthorizedError(error: any): Observable<never> {
    // Catch and handle the 403 specifically - in that case the user has no access to the portal or organization, so we redirect to "not-found"
    if (error.status === 403 && !error?.error?.orgId) {
      this.router.navigateByUrl('/not-found', { skipLocationChange: true });
      return EMPTY;
    }
    return throwError(() => error);
  }
}
