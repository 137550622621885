import { LocalizedValue } from '@celum/core';

import { BackgroundSetting } from './background-setting.model';
import { DesignThemeConfiguration } from './design-theme-configuration';
import { FileType } from '../asset/asset.entity';

export type WidgetConfigMap = { [widgetId: string]: WidgetConfiguration<any> };

// maps widgets to their asset references, e.g. an image carousel widget might have multiple images
export type WidgetAssetReferencesMap = { [widgetId: string]: { referenceId: string; context: string }[] };

export const FALLBACK_PAGE_ID = 'celum-fallback-page';

export type AssetVariant = 'original' | 'large' | 'medium' | 'small' | 'pdf' | 'audio' | 'video';

export type AssetVariantsByFileType = { [variantKey in FileType]?: AssetVariant[] };

// maps asset reference ids to their asset references, as long as the asset is used in at least one widget, it is stored here
export type AssetReferences = { [assetReferenceId: string]: AssetReference };

// simple representation of an asset for showing it in widgets
export type AssetReference = {
  id: string;
  assetId: string;
  libraryId: string;
  variant: string;
  // AssetReferences might be created while processing is still running and the fileType not yet available. The hints help to decide
  // which variant to set when the fileType is available.
  variantHints?: AssetVariantsByFileType;
  version: number;
  url: string;
  processing?: boolean;
  dimensions?: { width: number; height: number };
};

// object for creating or updating an asset reference
export type AssetReferenceData = Omit<AssetReference, 'id'>;

// asset configuration part of a portal configuration
export type AssetConfigs = {
  widgetAssetMap: WidgetAssetReferencesMap;
  assetReferences: AssetReferences;
};

export type Configuration = {
  general: GeneralConfiguration;
  pages?: PageConfiguration[];

  widgetMap?: WidgetConfigMap;

  assets?: AssetConfigs;

  themeConfiguration?: DesignThemeConfiguration;
};

export enum PageType {
  PAGE = 'page',
  FALLBACK = 'fallback'
}

export type PageConfiguration = {
  id: string;
  name?: LocalizedValue;
  tabTitle?: LocalizedValue;
  background?: BackgroundSetting;
  slug: string;
  widgets: WidgetIdentifier[];
  pageType: PageType;
};

export type WidgetIdentifier = { id: string; type: WidgetType };

export type WidgetConfiguration<C> = WidgetIdentifier & { config: C };

export type Disclaimer = {
  activated: boolean;
  /* Has any disclaimer ever been published before for the given portal */
  hasBeenPublished?: boolean;
  text?: LocalizedValue;
  /* The published disclaimer text after it has been reset by the user and the portal published */
  resetDisclaimerText?: LocalizedValue;
  /* The published disclaimer version after it has been reset by the user and the portal published  */
  resetDisclaimerVersion?: number;
  /* The disclaimer confirmations should be reset on the next portal publication. */
  resetPending?: boolean;
};

export type GeneralConfiguration = {
  settings: GeneralSettings;
  widgets: GeneralWidgets;
};

export type GeneralWidgets = {
  /* Optional header element. This is considered to be a layout widget! */
  header?: WidgetIdentifier;
};

/**
 * This interface is used to define colors in the portal/widget configurations.
 * Compared to NamedColor, the name property is not needed, because we use the id/color properties to get the actual color, and we also don't want to store it
 * in the configuration id: if a css variable must be used the id should contain the variable name without the prefix (e.g.: 'primary' for --color-primary).
 * the color property is used as fallback if the variable for the id is not set color: can be a hex, hsl or hsla color (e.g.: #ffffff,hsl(300,100%,50%),
 * hsla(10, 20%, 40%, 0.5). if not set, the id is expected to be set and will be used to display the color opacity: number between 0 and 1. defines the opacity
 * for the color. if not set, 1 is used as default
 */
export interface ConfigurationColor {
  id?: string;
  color?: string;
  opacity?: number;
}

export type WidgetStyle = { [key: string]: ConfigurationColor };

export type GeneralSettings = {
  background: BackgroundSetting;
  locales: string[];

  /*
   * The main font which should be used throughout the portal.
   * This setting will be ignored if a theme is set in favor of the theme's main font.
   * Old portals which were migrated (1.7.0 release) might not have a main font set, new portals always have a main font set.
   */
  mainFont?: string;
  disclaimer?: Disclaimer;
};

export enum WidgetType {
  ASSET_GALLERY = 'asset-gallery',
  GENERAL = 'general',
  GREETER = 'greeter-widget',
  HEADER = 'header',
  IMAGE = 'image-widget',
  LAYOUT = 'layout-widget',
  SHARE = 'share-info',
  TEXT = 'text-widget',
  VIDEO = 'video-widget',
  CONTENT_TEMPLATE = 'content-template-widget',
  BASKET = 'basket-widget',
  FALLBACK = 'fallback-widget'
}
