import { PageConfiguration, PageType } from '@celum/portals/domain';

/**
 * Checks if a page has a custom slug different from its ID.
 *
 * @param {PageConfiguration} pageConfiguration - the page configuration.
 * @returns {boolean} - true if a custom slug was defined for the page
 */
export function hasPageCustomSlug(pageConfiguration: PageConfiguration): boolean {
  return pageConfiguration.slug !== pageConfiguration.id;
}

/**
 * Filters and returns only the pages that have an appropriate page type.
 *
 * @param {PageConfiguration[]} pageConfigurations - An array of page configurations.
 * @returns {PageConfiguration[]} - An array of pages where the pageType is found.
 */
export function portalPages(pageConfigurations: PageConfiguration[], pageTypes: PageType[] = [PageType.PAGE]): PageConfiguration[] {
  return pageConfigurations?.filter(page => pageTypes.includes(page.pageType)) ?? [];
}

/**
 * Finds and returns the first page with a page type of 'FALLBACK'.
 *
 * @param {PageConfiguration[]} pageConfigurations - An array of page configurations.
 * @returns {PageConfiguration | undefined} - The first page with a pageType of 'FALLBACK', or undefined if not found.
 */
export function fallbackPage(pageConfigurations: PageConfiguration[]): PageConfiguration {
  return pageConfigurations?.find(page => page.pageType === PageType.FALLBACK);
}

/**
 * Gets the page slug or the page id in case of the 'FALLBACK' page which doesn't have a slug.
 *
 * @param {PageConfiguration} pageConfiguration - the page configuration.
 * @returns {string} - the slug (or the page id for the 'FALLBACK' page)
 */
export function pageSlugWithFallback(pageConfiguration: PageConfiguration): string {
  return pageConfiguration?.slug || (pageConfiguration?.pageType === PageType.FALLBACK ? pageConfiguration.id : undefined);
}
